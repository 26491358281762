import { cloneElement, useRef, useEffect, useState } from 'react'
import { useOverlay, useModal, OverlayContainer } from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import { useIsSSR } from '@react-aria/ssr'
import { mergeProps } from '@react-aria/utils'
import clsx from 'clsx'

import Button from '@/components/ui/button'

// Hooks
import useTranslation from '@/hooks/translation'
import usePrefersReducedMotion from '@/hooks/prefers-reduced-motion'
import useScroll from '@/hooks/scroll'

// Icon
import Cross from '@/assets/svg/cross.svg'

import styles from './index.module.css'

function Modal({
  isVisible,
  children,
  onClose: propsOnClose,
  isClosable = true,
  isDismissable = isClosable,
  closeButtonOutside = true,
  size = 'md',
  customSize,
  zIndex = 'z-20',
  'aria-label': ariaLabel,
  isAlert,
  unstyled,
  className,
  footer,
  footerOutside = true,
  header,
  overflowOnlyContent = false,
  animation,
}) {
  const ref = useRef()

  const prefersReducedMotion = usePrefersReducedMotion()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const onClose = () => {
    setIsOpen(false)

    if (prefersReducedMotion) {
      propsOnClose?.()
    } else {
      setTimeout(() => {
        propsOnClose?.()
      }, 200)
    }
  }

  const { overlayProps, underlayProps } = useOverlay(
    {
      isOpen: isVisible,
      onClose,
      isDismissable: isDismissable,
      isKeyboardDismissDisabled: !isDismissable,
    },
    ref
  )

  const { modalProps } = useModal()

  const { dialogProps, titleProps } = useDialog(
    { role: isAlert ? 'alertdialog' : 'dialog', 'aria-label': ariaLabel },
    ref
  )

  const { t } = useTranslation()

  const content =
    typeof children === 'function'
      ? // Consumers should always use this onClose when manually toggling
        // `isVisible` to make sure animations run properly
        children({ titleProps, onClose })
      : children || null

  return (
    <section
      className={clsx(
        'fixed top-0 left-0 right-0 h-app motion-safe:transition-colors',
        zIndex,
        animation,
        styles.overlayClose,
        overflowOnlyContent ? 'overflow-none' : 'overflow-auto',
        { [styles.overlayOpen]: isOpen }
      )}
      {...underlayProps}
    >
      <div
        className={clsx(
          'h-full min-h-full',
          'md:flex md:items-center md:justify-center'
        )}
      >
        <FocusScope contain restoreFocus autoFocus>
          <div
            {...mergeProps(overlayProps, dialogProps, modalProps)}
            ref={ref}
            className={clsx(
              size === 'custom' ? customSize : null,
              {
                [styles.modalLg]: !unstyled && size === 'lg',
                [styles.modalMd]: !unstyled && size === 'md',
                [styles.modalXl]: !unstyled && size === 'xl',
              },
              'h-full'
            )}
          >
            <div
              className={clsx(
                'motion-safe:transition-all transform h-full',
                className,
                {
                  [styles.modalClose]: !animation && !isOpen,
                  [styles.modalOpen]: !animation && isOpen,
                  [styles.modalCloseRight]:
                    animation === 'fromRight' && !isOpen,
                  [styles.modalOpenRight]: animation === 'fromRight' && isOpen,
                  [styles.modalCloseFade]: animation === 'fade' && !isOpen,
                  [styles.modalOpenFade]: animation === 'fade' && isOpen,
                }
              )}
              data-state={isOpen ? 'open' : 'close'}
            >
              {unstyled ? (
                content
              ) : (
                <div>
                  <div
                    className={clsx(
                      'md:relative flex flex-col justify-end md:justify-start',
                      overflowOnlyContent ? 'h-screen' : 'h-screen md:h-auto'
                    )}
                  >
                    {isClosable ? (
                      <div className="flex justify-end py-10 pt-5">
                        <Button
                          onClick={onClose}
                          unstyled
                          className={clsx(
                            'flex items-center justify-center rounded-full w-40 h-40 bg-white text-dark',
                            {
                              'absolute mr-10 mt-20 border border-gray-border':
                                !closeButtonOutside,
                            },
                            { shadow: closeButtonOutside }
                          )}
                          aria-label={t('components.modal.close')}
                        >
                          <Cross className="w-15" />
                        </Button>
                      </div>
                    ) : null}
                    {header && (
                      <div className="bg-white rounded-t-10">{header}</div>
                    )}
                    <div
                      className={clsx(
                        'overflow-auto',
                        footer && !footerOutside ? 'bg-white' : '',
                        footer && footerOutside ? 'mb-[150px]' : '',
                        !footer ? 'mb-20' : '',

                        !header ? 'rounded-t-10' : '',
                        !footer || (footer && footerOutside)
                          ? 'rounded-b-10'
                          : ''
                      )}
                    >
                      {content}
                    </div>
                    {footer && !footerOutside && (
                      <div className="rounded-b-10 mb-20 bg-white">
                        {footer}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* We need the footer outside of the transform transition of the modal
              because `position: fixed` does not work properly if a parent has
              the `transform` css property. */}
            {footer && footerOutside ? (
              <div
                className={clsx(
                  'fixed bottom-0 left-0 right-0 w-full motion-safe:transition-all transform',
                  styles.footerClose,
                  { [styles.footerOpen]: isOpen }
                )}
              >
                {footer}
              </div>
            ) : null}
          </div>
        </FocusScope>
      </div>
    </section>
  )
}

function ModalContainer(props) {
  const isSSR = useIsSSR()

  useScroll({ isDisabled: !props.isVisible })

  return (
    <>
      {props.trigger ? cloneElement(props.trigger) : null}
      {!isSSR && props.isVisible ? (
        <OverlayContainer>
          <Modal {...props} />
        </OverlayContainer>
      ) : null}
    </>
  )
}

export default ModalContainer
